import React, { ReactElement } from "react";

export default function WhyUs(): ReactElement {
    return (
        <section>
            <h2 className="text-center mt-14 font-bold text-xl md:text-3xl text-[#2A4A63]">
                What Is the Camp Lejeune Lawsuit?
            </h2>
            <p className="mt-3 text-sm lg:text-base  sm:text-center leading-8 max-w-[957px] mx-auto font-light">
                The Camp Lejeune lawsuit aims to give money to people who lived
                or worked at the Marine Corps base in North Carolina between
                1953 and 1987 and were exposed to toxic water. The contamination
                affected over a million people and resulted in illnesses such as
                cancer and birth defects. The lawsuit wants to hold the
                government accountable for not doing enough and compensate those
                who have been hurt.
            </p>
        </section>
    );
}
